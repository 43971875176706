// Mixins
// --------------------------

@mixin fa-icon() {
    display: inline-block;
    font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin fa-icon-rotate($degrees, $rotation) {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
    -webkit-transform: scale($horiz, $vert);
    -ms-transform: scale($horiz, $vert);
    transform: scale($horiz, $vert);
}
