/* ========================================================================
     Component: typo
 ========================================================================== */

body {
    font-family: 'Source Sans Pro', sans-serif;
    color: #656565;
}

h1,
h2,
h3,
h4 {
    font-weight: bold;
}
